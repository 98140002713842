import { isMobile } from "mobile-device-detect";
import { useEffect } from "react";
import { Analytics } from "utils/Analytics/AnalyticsHelper";

type ProfileParams = {
  showModal: boolean;
  setShowModal: any;
  username?: any;
  profile_url?: string;
};
const EmbedFrame = (props: ProfileParams) => {
  const { showModal, setShowModal, username, profile_url } = props;
  useEffect(() => {
    Analytics.track("Public Popup Triggered", {
      type: "embed",
      action: "landing_page",
      username: username || "NA",
    });
  }, []);

  const InFrame = () => {
    return (
      <div className="tm-iframe-container">
        {!isMobile && (
          <button
            className="close-icon cursor-hover"
            onClick={() => {
              Analytics.track("Public Popup Closed", {
                screen: "landing",
                type: "embed",
                title: "Live Demo",
              }),
                setShowModal(false);
            }}
          >
            <span className="icon-text">&#x2715;</span>
          </button>
        )}
        <iframe
          id="embed-topmate-profile"
          src={
            profile_url
              ? profile_url
              : `https://topmate.io/embed/profile/${username}?utm_source=Landingpage&utm_campaign=embed`
          }
          width="100%"
          className="iframe-box"
          style={{
            height: "100vh",
            border: 0,
            boxShadow: "none",
            outline: "none",
          }}
        />
      </div>
    );
  };
  return (
    <>
      {showModal ? (
        <>
          {isMobile ? (
            <main className="tm-mobile-container">
              <section style={{ height: "90%" }} className="tm-mobile-section">
                <article className="tm-mobile-frame">
                  <InFrame />
                </article>
              </section>
              <section
                className="tm-mobile-close-bg"
                onClick={() => {
                  setShowModal(false);
                }}
              ></section>
            </main>
          ) : (
            <div className="tm-modal-container">
              <InFrame />
            </div>
          )}
        </>
      ) : null}
    </>
  );
};

export default EmbedFrame;
