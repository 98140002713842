import { useQuery } from "@tanstack/react-query";
import { Avatar, Col, Row, Skeleton, Tabs, Typography } from "antd";
import Container from "components/Common/Container";
import ErrorBoundary from "components/Common/ErrorBoundary";
import TImage from "components/Common/TImage";
import { useEffect } from "react";
import { APIStateKeysEnum } from "services/ApiHooks";
import { getExpertCategories } from "services/dashboard-services";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import { Analytics } from "utils/Analytics/AnalyticsHelper";
import config from "utils/config";

const { Title, Text, Paragraph } = Typography;

interface IExpertsSliderProps {
  category: any;
}

export const initTextSlide = () => {
  const userSlider: any = document.querySelector(".js-user-slider");
  const userWordsData = userSlider.getAttribute("data-words").split(",");
  let userWords;
  let userSliderCount = 0;
  let userSliderStarted = false;
  let userSliderTimeout;
  let userSliderPrev: any;
  let userSliderCur;

  const userSliderRun = () => {
    clearTimeout(userSliderTimeout);

    userSliderTimeout = setTimeout(
      function () {
        userSlider.classList.add("js-loaded");

        userSliderStarted = true;

        userSliderCur = userWords[userSliderCount];

        if (userSliderPrev) {
          userSliderPrev.classList.add("js-user-slide-out");
          userSliderCur.classList.remove("js-user-slide-out");
          userSliderCur.classList.add("js-user-slide-in");
        } else {
          userSliderCur.classList.add("js-user-slide-init");
        }

        userSlider.style.width = userSliderCur.clientWidth + "px";
        userSlider.style.height = userSliderCur.clientHeight + "px";

        userSliderPrev = userSliderCur;

        if (userSliderCount === userWords?.length - 1) {
          userSliderCount = 0;
        } else {
          userSliderCount++;
        }

        userSliderRun();
      },
      userSliderStarted ? 2000 : 0
    );
  };

  const userSliderInit = () => {
    userSlider.innerHTML = "";

    for (let i = 0; i < userWordsData?.length; ++i) {
      const _span = document.createElement("span");
      const _text = document.createTextNode(userWordsData[i]);
      const _elm = _span.appendChild(_text);

      userSlider.appendChild(_span);
    }

    userWords = userSlider.childNodes;

    userSlider.style.width = userSlider.clientWidth + "px";
    userSlider.style.height = userSlider.clientHeight + "px";

    userSliderRun();
  };

  userSliderInit();
};

const HomeDiscovery: React.FC = () => {
  const { data: expertsData, isLoading: expertsDataLoading } = useQuery({
    queryKey: [APIStateKeysEnum.ExpertCategories],
    queryFn: getExpertCategories,
    staleTime: 3600000,
  });

  const ExpertsSlider: React.FC<IExpertsSliderProps> = (
    props: IExpertsSliderProps
  ) => {
    const { category } = props;
    const { name, experts } = category;

    return (
      <Container id="homepage-scroll">
        <Row justify="center">
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 22, offset: 0 }}>
            <ErrorBoundary>
              <Swiper
                slidesPerView={1.6}
                slidesPerGroup={1}
                spaceBetween={12}
                className="hd-swiper"
                breakpoints={{
                  480: {
                    slidesPerView: 2,
                    slidesPerGroup: 1,
                    spaceBetween: 12,
                    loop: true,
                  },
                  768: {
                    slidesPerView: 1.7,
                    slidesPerGroup: 1,
                    spaceBetween: 16,
                    loop: true,
                  },
                  1024: {
                    slidesPerView: 2.3,
                    slidesPerGroup: 1,
                    spaceBetween: 22,
                    loop: false,
                  },
                  1280: {
                    slidesPerView: 4,
                    slidesPerGroup: 1,
                    spaceBetween: 32,
                    loop: false,
                  },
                }}
              >
                {experts
                  ?.sort((a, b) => a - b)
                  ?.map((ex, idx) => (
                    <SwiperSlide
                      className="hd-expert-card"
                      key={idx}
                      onClick={(): any => {
                        Analytics.track("Creator Profile Clicked", {
                          "Expert Name": ex?.user?.username || "NA",
                          "Category Type": name || "NA",
                          Position: idx + 1 || "NA",
                        });
                        window.open(
                          `${config.BASE_URL}${ex?.user?.username}`,
                          "_blank"
                        );
                      }}
                    >
                      <Paragraph className={`hd-expert-bg bg-${idx}`} />
                      <Avatar
                        src={
                          <TImage
                            src={ex?.user?.profile_pic}
                            alt={ex?.user?.first_name}
                            width={120}
                            height={120}
                          />
                        }
                        alt={ex?.user?.first_name}
                        className="hd-expert-profile-pic"
                        shape="circle"
                        size={120}
                      />
                      <Paragraph
                        className="hd-expert-name"
                        ellipsis={{ rows: 1 }}
                      >
                        {(ex?.user?.first_name + " " + ex?.user?.last_name)
                          ?.length > 10
                          ? ex?.user?.first_name
                          : ex?.user?.first_name + " " + ex?.user?.last_name}
                      </Paragraph>
                      <Paragraph
                        className="hd-expert-link"
                        ellipsis={{ rows: 3 }}
                      >
                        {ex?.user?.short_description}
                      </Paragraph>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </ErrorBoundary>
          </Col>
        </Row>
      </Container>
    );
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (window.location.hash === "#discovery") {
      const elm: any = document.getElementById("discovery");
      const top = 20 + elm?.getBoundingClientRect().top;
      window.scrollTo({
        top: top,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <Container type="fluid" className="home-discovery" id="discovery">
      {expertsDataLoading ? (
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }}>
          <Skeleton active paragraph={{ rows: 6 }} />
          <Skeleton active paragraph={{ rows: 6 }} />
        </Col>
      ) : (
        <Container>
          {" "}
          <Title level={2} className="hd-title">
            For people <b>making Impact</b>
          </Title>
          {/* Tab Container */}
          <Row className="hd-tabs">
            <Col xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }}>
              <Tabs
                defaultActiveKey="1"
                centered
                tabBarGutter={14}
                animated
                onTabClick={(category): void =>
                  Analytics.track("Profile Category Clicked", {
                    "Category Type": category || "NA",
                  })
                }
                items={expertsData?.map((category) => {
                  const { name } = category;
                  return {
                    label: name,
                    key: name,
                    children: <ExpertsSlider category={category} />,
                  };
                })}
              />
            </Col>
          </Row>{" "}
        </Container>
      )}
    </Container>
  );
};

export default HomeDiscovery;
