import Head from "next/head";
import config from "utils/config";

const HomeSEO: React.FC = () => {
  return (
    <Head>
      <title>Start your creator business today</title>
      <meta
        name="description"
        content="Turn your passion and knowledge into a thriving business. Help your audience get ahead in life"
      />
      <meta property="og:title" content="Start your creator business today" />
      <meta
        property="og:description"
        content="Turn your passion and knowledge into a thriving business. Help your audience get ahead in life"
      />
      <meta
        property="og:image"
        content={`${config.BASE_URL}meta-referal-image.png`}
      />
      <meta
        property="twitter:image"
        content={`${config.BASE_URL}meta-referal-image.png`}
      />
      <meta
        property="og:image:secure_url"
        content={`${config.BASE_URL}meta-referal-image.png`}
      />
    </Head>
  );
};

export default HomeSEO;
