import { Col, Collapse, Row, Typography } from "antd";
import Container from "components/Common/Container";
import TImage from "components/Common/TImage";
import Link from "next/link";
import { Analytics } from "utils/Analytics/AnalyticsHelper";

const { Title, Text } = Typography;
const { Panel } = Collapse;

const FAQHome: React.FC = () => {
  const FAQ = [
    {
      id: 1,
      title: "What is topmate?",
      answer:
        "Topmate is a tool that enables you to connect with your audience through 1:1 sessions - to share your knowledge and monetise your time better. Just create your personal link, add your services, availability and charges; and share your link with your followers. Your followers can now book a call to connect with you",
    },
    {
      id: 2,
      title: "Can I conduct paid sessions?",
      answer:
        "Absolutely, we enable you to conduct both free and paid sessions with your followers",
    },
    {
      id: 3,
      title: "How much can I charge for my sessions?",
      answer:
        "It really depends on how much value you think you're providing and how much are your followers willing to pay. For idea, our creators charge anywhere from $50 to $500 for a session",
    },
    {
      id: 4,
      title: "How do the payments work?",
      answer:
        "You can collect payments from anywhere in the world in your preferred currency. Payouts are Instant with the help of our integration with Stripe and Paypal",
    },
    {
      id: 5,
      title: "Wait, is Topmate really free?",
      answer:
        "Yes! We're free to use and charge a very tiny commission on your earnings (7%). So that we grow only when you grow",
    },
    {
      id: 6,
      title: "What are the transaction charges?",
      answer:
        "Payment gateways like Stripe and PayPal typically charge around 3% of the transaction amount. This is beyond our control",
    },
  ];
  return (
    <Container type="fluid" className="home-faq">
      <Container>
        <Row justify="center">
          <Col xs={22} md={12}>
            <Title level={3} className="header">
              Frequently asked questions
            </Title>
            <Title level={4} className="sub-header">
              Can&apos;t find the answer you are looking for?
            </Title>

            <Link href="/contact">
              <Text strong type="success" className="sub-header">
                Reach out to us
              </Text>
            </Link>
          </Col>
          <Col xs={22} md={12}>
            {/* FAQ's Accordion */}
            <Collapse
              ghost
              className="faq-collapse"
              expandIconPosition="end"
              accordion
              expandIcon={() => (
                <TImage
                  src="/images/homepage/icon-topmate-faq.svg"
                  alt="FAQ Icon"
                  width={15}
                  height={15}
                />
              )}
              onChange={(faq): void => {
                if (faq) {
                  const x = FAQ.find((f) => Number(f?.id) === Number(faq));
                  Analytics.track("FAQ Clicked", { Title: x?.title });
                }
              }}
            >
              {FAQ?.map((faq) => (
                <Panel header={faq?.title} key={faq?.id} className="faq-item">
                  <p className="faq-answer">{faq?.answer}</p>
                </Panel>
              ))}
            </Collapse>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default FAQHome;
