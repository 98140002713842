import { Col, Collapse, Row, Typography } from "antd";
import Container from "components/Common/Container";
import TImage from "components/Common/TImage";
import { signupRedirect } from "components/Layouts/Public/PublicLayout";
import { requestInviteEvent } from "components/PublicPages/Homepage/Homepage";
import { motion } from "framer-motion";
import Link from "next/link";
import { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import EmbedFrame from "../EmbedProfile/EmbedModal";

const { Paragraph, Text } = Typography;
const { Panel } = Collapse;

const HomeFeaturesSection = ({ variant = "" }) => {
  const [current, setCurrent] = useState<string>("1");
  const [showDemo, setShowDemo] = useState<boolean>(false);

  const CarouselData = [
    {
      key: 1,
      title: "Offer 1:1 sessions",
      description: `Mentorship sessions, consultations, discovery calls - do what you do best.
       We take care of everything else`,
      image: "/images/homepage/feature-2-1.svg",
    },
    {
      key: 2,
      title: "Setup Priority DM in seconds",
      description: `Let your followers ask text based Priority DM. Then answer as per your convenience`,
      image: "/images/homepage/feature-2-2.svg",
    },
    {
      key: 3,
      title: "Host a webinar",
      description: `Connect with 100s of followers at once. Classes, group calls, 
      workshops - do them all`,
      image: "/images/homepage/feature-2-3.svg",
    },
    {
      key: 4,
      title: "Bundle your services",
      description: `Create packages of all your services. Perfect for high-ticket and 
      long term engagements`,
      image: "/images/homepage/feature-2-4.svg",
    },
    {
      key: 5,
      title: "Sell courses & products",
      description: `Sell courses, exclusive content, guides, ebooks, templates and everything else. Start earning passively`,
      image: "/images/homepage/feature-2-5.svg",
    },
    {
      key: 6,
      title: "Sell a subscription",
      description: `Sell monthly subscriptions to your services. Build recurring revenue sources`,
      image: "/images/homepage/feature-2-6.svg",
    },
  ];

  return (
    <Container type="fluid" className="homepage-features">
      <Container>
        {/* Post 1 */}
        {showDemo && (
          <EmbedFrame showModal={showDemo} setShowModal={setShowDemo} />
        )}
        <Row className="features-post" justify="center">
          <Col
            xs={{ span: 22, offset: 0, order: 2 }}
            md={{ span: 12, offset: 0, order: 1 }}
          >
            <Paragraph className="feature-header">
              Create your Topmate page in a{" "}
              <Text style={{ color: "#DE7E47" }}>flash</Text>
            </Paragraph>
            <Paragraph className="feature-subheader">
              {variant === "gumroad"
                ? "Sell documents, do 1:1s or host webinars - all with a few clicks ✌🏻"
                : "Start earning $$ by the time you finish reading our website"}
            </Paragraph>
            <Paragraph className="featured-cta">
              <Link
                href={signupRedirect()}
                onClick={(): void => requestInviteEvent("below_flash")}
              >
                Launch your page ↗️
              </Link>
            </Paragraph>
          </Col>
          <Col xs={{ span: 0 }} md={{ span: 10, offset: 2, order: 2 }}>
            <TImage
              src="/images/homepage/feature-1.svg"
              alt="Create your link in just 2 mins"
              className="feature-img"
              width={500}
              height={360}
            />
          </Col>
          <Col xs={{ span: 22, offset: 0, order: 1 }} md={{ span: 0 }}>
            <TImage
              src="/images/homepage/feature-mobile-1.svg"
              alt="Create your link in just 2 mins"
              className="feature-img"
              width={350}
              height={245}
            />
          </Col>
        </Row>

        {/* Post 2 */}
        <Row className="features-post" justify="center">
          <Col
            xs={{ span: 24, offset: 0, order: 2 }}
            md={{ span: 13, offset: 1, order: 2 }}
          >
            <Collapse
              defaultActiveKey={["1"]}
              ghost
              accordion
              activeKey={current}
              expandIcon={() => null}
              onChange={(val): void => {
                if (val?.length) {
                  setCurrent(val[0]);
                } else {
                  setCurrent("1");
                }
              }}
              className="feature-collapse"
            >
              {CarouselData?.map((item) => (
                <Panel
                  header={
                    <motion.div
                      exit={{ opacity: 0 }}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 1.2 }}
                      style={{ width: "100%" }}
                    >
                      <Row align="middle">
                        <Col xs={2} md={2}>
                          <Paragraph className="fc-number">
                            0{item?.key}
                          </Paragraph>
                        </Col>
                        <Col span={20} className="fc-title">
                          {item?.title}
                        </Col>
                        <Col span={2}>
                          <FaChevronDown color="#C95230" fontSize={20} />
                        </Col>
                      </Row>
                    </motion.div>
                  }
                  key={item?.key}
                  className="fc-header"
                >
                  <Row align="middle" justify="center">
                    <Col xs={0} md={2}></Col>
                    <Col xs={22} md={22}>
                      <Paragraph className="fc-description">
                        {item?.description}
                      </Paragraph>
                      <TImage
                        src={item?.image}
                        alt={item?.title}
                        className="feature-img mobile-only"
                        width={350}
                        height={245}
                      />
                    </Col>
                  </Row>
                </Panel>
              ))}
            </Collapse>
          </Col>
          <Col
            xs={{ span: 0, offset: 0, order: 1 }}
            md={{ span: 10, offset: 0, order: 1 }}
          >
            {CarouselData?.filter(
              (x) => String(current) === String(x?.key)
            )?.map((item) => (
              <motion.div
                exit={{ opacity: 0, y: 60 }}
                initial={{ opacity: 0, y: 60 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.1 }}
                key={item?.key}
              >
                <TImage
                  src={item?.image}
                  alt={item?.title}
                  className="feature-img"
                  width={350}
                  height={245}
                />
              </motion.div>
            ))}
          </Col>
        </Row>

        {/* Post 3 */}
        {/* <Row className="features-post" justify="center">
          <Col
            xs={{ span: 22, offset: 0, order: 2 }}
            md={{ span: 12, offset: 0, order: 1 }}
          >
            <Paragraph className="feature-header">
              <Text type="danger">Build</Text> and grow{" "}
              <br className="mobile-hide" />
              10x faster
            </Paragraph>
            <Paragraph className="feature-subheader">
              We take care of the bookings, communication, payments,
              testimonials and optimise conversions.
            </Paragraph>
            <Paragraph className="featured-cta">
              <Link
                href={signupRedirect()}
                onClick={(): void => requestInviteEvent("below_business")}
              >
                Start monetising your time ↗️
              </Link>
            </Paragraph>
          </Col>
          <Col
            xs={{ span: 22, offset: 0, order: 1 }}
            md={{ span: 10, offset: 2, order: 2 }}
          >
            {isMobile ? (
              <TImage
                src="/images/homepage/feature-mobile-3.svg"
                alt="Let your followers seamlessly book your time or ask queries"
                className="feature-img"
                width={350}
                height={245}
              />
            ) : (
              <TImage
                src="/images/homepage/feature-3.svg"
                alt="Let your followers seamlessly book your time or ask queries"
                className="feature-img"
                width={500}
                height={360}
              />
            )}
          </Col>
        </Row> */}

        {/* Post 4 */}
        {/* <Row className="features-post" justify="center">
          <Col
            xs={{ span: 22, offset: 0, order: 2 }}
            md={{ span: 12, offset: 0, order: 1 }}
          >
            <Paragraph className="feature-header">
              <Text type="danger">Save hours</Text> spent on managing multiple
              tools
            </Paragraph>
            <Paragraph className="feature-subheader">
              Topmate integrates with your existing ecosystem and keep your data
              safe.
            </Paragraph>
            <Paragraph className="featured-cta">
              <Link
                href={signupRedirect()}
                onClick={(): void => requestInviteEvent("below_tool")}
              >
                Get started for free ↗️
              </Link>
            </Paragraph>
          </Col>
          <Col
            xs={{ span: 22, offset: 0, order: 1 }}
            md={{ span: 10, offset: 2, order: 2 }}
          >
            {isMobile ? (
              <TImage
                src="/images/homepage/feature-mobile-4.svg"
                alt="Manage everything through WhatsApp"
                className="feature-img"
                width={350}
                height={245}
              />
            ) : (
              <TImage
                src="/images/homepage/feature-4.svg"
                alt="Manage everything through WhatsApp"
                className="feature-img"
                width={500}
                height={360}
              />
            )}
          </Col>
        </Row> */}

        {/* Post 5 */}
        {/* <Row className="features-post" justify="center">
          <Col
            xs={{ span: 22, offset: 0, order: 2 }}
            md={{ span: 12, offset: 0, order: 1 }}
          >
            <Paragraph className="feature-header">
              Integrate your Topmate services on{" "}
              <Text type="danger">your website.</Text> Sell directly from your
              website
            </Paragraph>
            <Paragraph className="featured-cta">
              <Link
                href={`https://topmate.io/ankit?utm_source=Landingpage&utm_campaign=embed`}
                target="_blank"
                onClick={(): void =>
                  Analytics.track("expert_profile_clicked", {
                    screename: "landing",
                    username: "ankit",
                  })
                }
              >
                Request demo ↗️
              </Link>
            </Paragraph>
          </Col>
          <Col
            xs={{ span: 22, offset: 0, order: 1 }}
            md={{ span: 10, offset: 2, order: 2 }}
          >
            {isMobile ? (
              <TImage
                src="/images/homepage/feature-5.png"
                alt="Demo on your website"
                className="feature-img"
                width={350}
                height={245}
              />
            ) : (
              <TImage
                src="/images/homepage/feature-5.png"
                alt="Demo on your website"
                className="feature-img"
                width={500}
                height={360}
              />
            )}
            <TImage
              src={"/images/homepage/liveDemo.svg"}
              alt="Live Demo"
              width={180}
              className="home-embed-demo"
              onClick={(): void => {
                Analytics.track("Public Popup Clicked", {
                  type: "embed_demo_ankit",
                  cta: "live_demo",
                }),
                  setShowDemo(true);
              }}
            />
          </Col>
        </Row> */}
      </Container>
    </Container>
  );
};

export default HomeFeaturesSection;
