import { Col, Row, Typography } from "antd";
import Container from "components/Common/Container";
import LottieComponent from "components/Common/LottieRender";
import TImage from "components/Common/TImage";
import { useRouter } from "next/router";
import BFY1 from "public/images/homepage/img-bfy-1.svg";
import BFY2 from "public/images/homepage/img-bfy-2.svg";
import BFY3 from "public/images/homepage/img-bfy-3.svg";
import BFY4 from "public/images/homepage/img-bfy-4.svg";
import BFY5 from "public/images/homepage/img-bfy-5.svg";
import BFYM1 from "public/images/homepage/img-bfy-mobile-1.svg";
import BFYM2 from "public/images/homepage/img-bfy-mobile-2.svg";
import BFYM3 from "public/images/homepage/img-bfy-mobile-3.svg";
import BFYM4 from "public/images/homepage/img-bfy-mobile-4.svg";
import BFYM5 from "public/images/homepage/img-bfy-mobile-5.svg";
import AiLottie from "public/images/lotties/magic-wand.json";

const { Text, Title } = Typography;

const HomeBuiltForYou = () => {
  const router = useRouter();

  return (
    <Container type="fluid" className="home-built-for-you">
      <Container>
        <Row>
          <Col span={24} className="text-center">
            <Title className="title" level={2}>
              Built <Text strong>for</Text> you. With <Text strong>you.</Text>
            </Title>
            <Title level={3} className="subtitle">
              Feedback and user needs is always our top priority, Here&apos;s
              some features
              <br className="mobile-hide" />
              we shipped recently for and with the help of our users.
            </Title>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={0} md={24}>
            <Row justify="center" gutter={{ xs: 0, md: 30 }}>
              <Col xs={22} md={8}>
                <TImage src={BFY1} alt="Built for you" className="bfy-img" />
              </Col>
              <Col xs={22} md={16}>
                <Row gutter={{ xs: 0, md: 30 }}>
                  <Col span={12}>
                    <TImage
                      src={BFY2}
                      alt="Built for you"
                      className="bfy-img"
                    />
                  </Col>
                  <Col span={12} className="bfy-lottie">
                    <TImage
                      src={BFY3}
                      alt="Built for you"
                      className="bfy-img"
                    />
                    <LottieComponent
                      src={AiLottie}
                      play
                      style={{ left: 20, top: 0, width: 176 }}
                      loop={true}
                      className="lottie-animation"
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }} gutter={{ xs: 0, md: 30 }}>
                  <Col span={15}>
                    <TImage
                      src={BFY4}
                      alt="Built for you"
                      className="bfy-img"
                    />
                  </Col>
                  <Col span={9}>
                    <TImage
                      src={BFY5}
                      alt="Built for you"
                      className="bfy-img"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={22} md={0}>
            <Row gutter={12}>
              <Col xs={12}>
                <TImage src={BFYM1} alt="Built for you" className="bfy-img" />
              </Col>
              <Col xs={12} className="bfy-lottie">
                <TImage src={BFYM2} alt="Built for you" className="bfy-img" />
                <LottieComponent
                  src={AiLottie}
                  play
                  style={{ left: 0, top: 0, width: 156 }}
                  loop={true}
                  className="lottie-animation"
                />
              </Col>
            </Row>
            <TImage
              src={BFYM3}
              alt="Built for you"
              className="bfy-img"
              styles={{
                marginTop: 16,
              }}
            />
            <TImage
              src={BFYM4}
              alt="Built for you"
              className="bfy-img"
              onClick={() => router.push("/about")}
              styles={{
                marginTop: 16,
              }}
            />
            <TImage
              src={BFYM5}
              alt="Built for you"
              className="bfy-img"
              onClick={() => router.push("/about")}
              styles={{
                marginTop: 16,
              }}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default HomeBuiltForYou;
