import { Col, Row, Typography } from "antd";
import Container from "components/Common/Container";
import TImage from "components/Common/TImage";

const { Title } = Typography;

const HomepageServices = ({ variant = "" }) => {
  return (
    <Container type="fluid" className="homepage-services">
      <Container>
        <Row justify="center">
          <Col xs={{ span: 22, offset: 0 }} md={{ span: 24, offset: 0 }}>
            <Title level={2} className="hs-title">
              Creators <b>earn more</b> on topmate
            </Title>
          </Col>

          {/* Desktop Services */}
          <Col xs={{ span: 0, offset: 0 }} md={{ span: 24, offset: 0 }}>
            <Row gutter={20}>
              <Col span={6}>
                <TImage
                  src="/images/homepage/service-desktop-1.svg"
                  alt="Collect Payments Globally"
                  className="img-services"
                  width={100}
                />
              </Col>
              <Col span={10}>
                <TImage
                  src="/images/homepage/service-desktop-2.svg"
                  alt="Highlight Unlimited Testimonials"
                  className="img-services"
                  width={100}
                />
              </Col>
              <Col span={8}>
                <TImage
                  src="/images/homepage/service-desktop-3.svg"
                  alt="Showcase your social handles"
                  className="img-services"
                  width={100}
                />
              </Col>
            </Row>
          </Col>
          <Col
            xs={{ span: 0, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            style={{ marginTop: "24px" }}
          >
            <Row gutter={20}>
              <Col span={10}>
                <TImage
                  src="/images/homepage/service-desktop-4.svg"
                  alt="Full stack scheduling"
                  className="img-services"
                  width={100}
                />
              </Col>
              <Col span={6}>
                <TImage
                  src={"/images/homepage/service-desktop-5.svg"}
                  alt="Host Unlimited Services"
                  className="img-services"
                  width={100}
                />
              </Col>
              <Col span={8}>
                <TImage
                  src={"/images/homepage/service-desktop-6.svg"}
                  alt="Host Unlimited Services"
                  className="img-services"
                  width={100}
                />
              </Col>
            </Row>
          </Col>

          {/* Mobile Services */}
          <Col
            xs={{ span: 22, offset: 0 }}
            md={{ span: 0, offset: 0 }}
            className="homepage-mobile-services"
          >
            <Row gutter={10}>
              <Col span={12}>
                <div style={{ position: "relative" }}>
                  <TImage
                    src="/images/homepage/service-mobile-1.svg"
                    alt="Collect Payments Globally"
                    className="img-services"
                    width={100}
                  />
                </div>
              </Col>
              <Col span={12}>
                <div style={{ position: "relative" }}>
                  <TImage
                    src="/images/homepage/service-mobile-2.svg"
                    alt="Collect Payments Globally"
                    className="img-services"
                    width={100}
                  />
                </div>
              </Col>
            </Row>
            <TImage
              src="/images/homepage/service-mobile-3.svg"
              alt="Highlight Unlimited Testimonials"
              className="img-services"
              width={100}
            />
            <TImage
              src="/images/homepage/service-mobile-4.svg"
              alt="Host Unlimited Services"
              className="img-services"
              width={100}
            />
            <TImage
              src="/images/homepage/service-mobile-5.svg"
              alt="Full stack scheduling"
              className="img-services"
              width={100}
            />
            <TImage
              src="/images/homepage/service-mobile-6.svg"
              alt="Full stack scheduling"
              className="img-services"
              width={100}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default HomepageServices;
