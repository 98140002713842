import { useGoogleOneTapLogin } from "@react-oauth/google";
import { message } from "antd";
import Container from "components/Common/Container";
import PublicFooter from "components/Layouts/Public/PublicFooter/PublicFooter";
import PublicLayout from "components/Layouts/Public/PublicLayout";
import PublicNav from "components/Layouts/Public/PublicNav/PublicNav";
import FAQHome from "components/PublicPages/Homepage/FAQHome";
import HomeBuiltForYou from "components/PublicPages/Homepage/HomeBuiltForYou";
import HomeFeaturesSection from "components/PublicPages/Homepage/HomeFeaturesSection";
import HomeHeroSection from "components/PublicPages/Homepage/HomeHeroSection";
import HomeSEO from "components/PublicPages/Homepage/HomeSEO";
import HomepageServices from "components/PublicPages/Homepage/HomepageServices";
import ProfilePreview from "components/PublicPages/Homepage/ProfilePreview";
import Testimonials from "components/PublicPages/Homepage/Testimonials";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useSocialSignin } from "services/ApiHooks";
import {
  getPersonType,
  setRandomPersonType,
} from "utils/Analytics/ABTestingHelper";
import { getTraitsObj, identifyUser } from "utils/Analytics/Analytics";
import { Analytics } from "utils/Analytics/AnalyticsHelper";
import { getAuthToken, setAuthToken } from "utils/authentication";
import { getTimezone } from "utils/common";
import config from "utils/config";
import { getCookie, setCookie } from "utils/cookie-manager";
import { v4 as uuidv4 } from "uuid";
import HomeDiscovery from "./HomeDiscovery";
import PossibilitiesSection from "./PossibilitiesSection/PossibilitiesSection";

const HomeAISearch = dynamic(
  () => import("components/PublicPages/Homepage/HomeAISearch"),
  { ssr: false }
);

export const requestInviteEvent = (
  pos: string,
  type: string = "Landing Page"
): void => {
  Analytics.track("invite_clicked", {
    Position: pos,
    Type: type || "Landing Page",
  });
};

interface IHomePageProps {
  variant?: "IN" | "US" | "EU" | any;
}

const HomePage: React.FC<IHomePageProps> = () => {
  const isLoggedIn = getAuthToken() ? true : false;
  const socialSignIn = useSocialSignin();
  const { replace } = useRouter();
  const deviceID = getCookie("topmate_device_id");
  const HomeABSignupKey = "HomePageSignupAB";
  const [signupType, setSignupType] = useState("");
  const redirectToSetInfo = (token, username): void => {
    setAuthToken(token);
    setCookie("topmate_username", username);
    if (!deviceID) {
      setCookie("topmate_device_id", uuidv4());
    }
    replace({
      pathname: `/onboarding/about-you`,
      search: `?code=${token}&username=${username}`,
    });
  };

  const redirectToDashboard = (token, username): void => {
    setAuthToken(token);
    setCookie("topmate_username", username);
    if (!deviceID) {
      setCookie("topmate_device_id", uuidv4());
    }
    replace("/dashboard/home");
  };
  const GmailLoginSuccess = async (code) => {
    const payload = {
      provider: "g_one_tap",
      code: code,
      error: "",
      redirect_url: `${config.BASE_URL}auth/google`,
    };
    payload["timezone"] = getTimezone();
    try {
      const response = await socialSignIn.mutateAsync(payload);
      const { is_signup, token, user } = response;
      Analytics.track(`one_click_signup_successful`, {
        user: user?.username,
        email: user?.email,
        new_user: is_signup,
      });
      if (is_signup) {
        redirectToSetInfo(token, user?.username);
      } else redirectToDashboard(token, user?.username);
      const traitsObj = getTraitsObj(user);
      identifyUser(traitsObj?.id, traitsObj);
    } catch (error) {
      message.error("Error occuried, Try again.");
    }
  };

  const login = useGoogleOneTapLogin({
    // use_fedcm_for_prompt: true,
    onSuccess: (credentialResponse) => {
      Analytics.track("one_click_signup_clicked", {
        status: "success",
      });
      GmailLoginSuccess(credentialResponse?.credential);
    },
    onError: () => {
      Analytics.track("one_click_signup_clicked", {
        status: "failure",
      });
      message.error("Login failed");
    },
    promptMomentNotification: (isDisplayMoment: any) => {
      Analytics.track("one_click_signup_action", {
        action: isDisplayMoment?.g,
        staus: isDisplayMoment?.h,
        not_shown_reason: isDisplayMoment?.j || "NA",
      });
    },
    disabled: isLoggedIn || signupType === "A" ? true : false,
    cancel_on_tap_outside: false,
  });

  const updatePersonType = (): void => {
    if (signupType === "") {
      const personTypeLocal = getPersonType(HomeABSignupKey);
      setSignupType(
        personTypeLocal !== null ? String(personTypeLocal).toUpperCase() : ""
      );
    }
  };

  useEffect(() => {
    setRandomPersonType(HomeABSignupKey);
    updatePersonType();
  }, []);

  return (
    <PublicLayout>
      <Container className="public-homepage" type="fluid">
        {/* SEO Component */}
        <HomeSEO />
        {/* Common Nav */}
        <PublicNav page="home" customTheme="#F8F7F2" />

        {/* Section 1 - Hero Section */}
        <HomeHeroSection />

        {/* Section 2 - Christmas Discovery Section */}
        {/* <ChristmasDiscovery /> */}

        {/* Section 2 - Expert Discovery Section */}
        <HomeDiscovery />

        {/* Section 3 - AI Search */}
        {/* <HomeAISearch page="Landing" /> */}

        {/* Section 4 - Features Section */}
        <HomeFeaturesSection />

        {/* Section 6 - Services Section */}
        <HomepageServices />

        {/* Section 3 - Linkedin preview Section */}
        <ProfilePreview />

        {/* Case study  */}
        <PossibilitiesSection />

        {/* New Section */}
        <HomeBuiltForYou />

        {/* Section 5 - Success Stories Section */}
        <Testimonials />

        {/* Section 9 - B2b */}
        {/* <HomeB2B /> */}

        {/* Section 10 - FAQ */}
        <FAQHome />

        {/* Footer */}
        <PublicFooter />
      </Container>
    </PublicLayout>
  );
};

export default HomePage;
