import { Col, Flex, Form, Input, message, Row, Typography } from "antd";
import Container from "components/Common/Container";
import FullScreenLoader from "components/Common/FullScreenLoader";
import LottieComponent from "components/Common/LottieRender";
import TImage from "components/Common/TImage";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { default as ImgStats2 } from "public/images/homepage/Groupstar.png";
import ImgStatsMobile1 from "public/images/homepage/home-stats-mobile-1.svg";
import ContentLoadingLottie from "public/images/lotties/ai-content-loading.json";
import { useState } from "react";
import CountUp from "react-countup";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { getLinkedinPreview } from "services/public-services";
import { Analytics } from "utils/Analytics/AnalyticsHelper";
import { LinkedinRegexPattern } from "utils/regex-helper";

const ProfilePreviewDrawer = dynamic(
  () => import("components/PublicPages/Homepage/ProfilePreviewDrawer"),
  {
    ssr: false,
  }
);

const { Title, Paragraph } = Typography;

let PREVIEW_INITIATED = false;

const ProfilePreview: React.FC = () => {
  const [linkedinDrawerOpen, setLinkedinDrawerOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [profileData, setProfileData] = useState<any>({});
  const [showExample, setShowExample] = useState<boolean>(false);
  const [LinkedinPreviewForm] = Form.useForm();
  const router = useRouter();
  const { linkedin } = router.query;

  const onValuesChanged = (values) => {
    switch (Object.keys(values)[0]) {
      case "linkedin": {
        if (!PREVIEW_INITIATED) {
          Analytics.track("Profile Preview Initiated");
          PREVIEW_INITIATED = true;
        }
        break;
      }
      default: {
        break;
      }
    }
  };
  const easingFn = (t, b, c, d) => {
    const ts = (t /= d) * t;
    const tc = ts * t;
    return b + c * (tc + -3 * ts + 3 * t);
  };
  const PublicProfilePreviewSubmit = async (fields) => {
    setLoading(true);
    const { linkedin: url } = fields;
    Analytics.track("Profile Preview Submitted", {
      url: showExample
        ? "https://www.linkedin.com/in/justinwelsh/"
        : linkedin
          ? linkedin
          : url,
    });
    try {
      const profile = await getLinkedinPreview({
        linkedin_url: showExample
          ? "https://www.linkedin.com/in/justinwelsh/"
          : linkedin
            ? linkedin
            : url,
      });
      setProfileData(profile);
      setLinkedinDrawerOpen(true);
      setLoading(false);
    } catch (error) {
      message.error("Some Error Occured! Please try again in some time!");
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (window.location.hash === `#earnings`) {
  //     setTimeout(() => {
  //       const elm: any = document.getElementById("earningscal");
  //       const top = 150 + elm?.getBoundingClientRect().top;
  //       window.scrollTo({
  //         top: top,
  //         behavior: "smooth",
  //       });
  //       if (linkedin) {
  //         LinkedinPreviewForm.submit();
  //       }
  //     }, 1000);
  //   }
  // }, [earnings]);

  return (
    <Container className="homepage-signup-widget" type="fluid" id="earnings">
      {loading && (
        <FullScreenLoader
          text={[
            "Analysing your LinkedIn profile",
            "Creating your Topmate page",
            "Calculating your earning potential",
          ]}
          lottie={
            <LottieComponent
              src={ContentLoadingLottie}
              play
              style={{
                width: "117px",
                height: "117px",
                margin: "0 auto",
              }}
            />
          }
        />
      )}

      <Container>
        <Row justify="center" style={{ zIndex: 10 }}>
          <Col
            xs={{ span: 22, offset: 0, order: 1 }}
            md={{ span: 12, offset: 0, order: 1 }}
            className="hsw-col"
          >
            <Title level={3} className="hsw-title">
              Calculate
              <br className="mobile-hide" /> your{" "}
              <br className="mobile-visible" />
              <b>
                earning <br className="mobile-hide" /> potential
              </b>
            </Title>
            <Title level={4} className="hsw-subtitle">
              Calculated using your LinkedIn profile and comparing based on
              similar profiles on topmate. Preview your topmate page.
            </Title>

            <Form
              onFinish={PublicProfilePreviewSubmit}
              form={LinkedinPreviewForm}
              onValuesChange={onValuesChanged}
              initialValues={{
                linkedin: linkedin ? linkedin : "",
              }}
            >
              <Form.Item
                name="linkedin"
                rules={[
                  {
                    pattern: LinkedinRegexPattern,
                    message:
                      "Invalid Linkedin link, please input proper linkedin link",
                  },
                ]}
              >
                <Input
                  placeholder="Your LinkedIn profile link"
                  className="hsw-input"
                  disabled={loading}
                  suffix={
                    <BsArrowRightCircleFill
                      onClick={() => LinkedinPreviewForm.submit()}
                      className="hsw-input-img"
                    />
                  }
                />
              </Form.Item>
              <Paragraph className="hsw-helper-text">
                No sign up required.{" "}
                <a
                  onClick={() => {
                    Analytics.track("Profile Preview Example Clicked");
                    LinkedinPreviewForm.resetFields();
                    setShowExample(true);
                    LinkedinPreviewForm.submit();
                  }}
                >
                  See example
                </a>
              </Paragraph>
            </Form>
          </Col>
          <Col
            xs={{ span: 0, offset: 1, order: 2 }}
            md={{ span: 11, offset: 1, order: 2 }}
          >
            <Paragraph className="stats2-conatiner">
              <Flex justify="space-between">
                <Col>
                  <Paragraph className="stats-text">
                    Creator earnings on topmate (2024)
                  </Paragraph>

                  <CountUp
                    start={1003040}
                    end={1003059}
                    duration={10}
                    enableScrollSpy
                    prefix="$ "
                    easingFn={easingFn}
                    useEasing
                    delay={0}
                  >
                    {({ countUpRef }) => (
                      <Paragraph className="stats-number">
                        {" "}
                        <span ref={countUpRef} />
                      </Paragraph>
                    )}
                  </CountUp>
                </Col>
                <TImage
                  src={"/images/homepage/earning-preview.svg"}
                  alt="Service Sold Stats"
                  width={100}
                />
              </Flex>
            </Paragraph>

            <Paragraph
              className="stats2-conatiner"
              style={{ marginTop: "32px" }}
            >
              <Flex justify="space-between">
                <Col>
                  <Paragraph className="stats-text">A community of</Paragraph>

                  <Paragraph className="stats-number"> 10k+ creators</Paragraph>
                </Col>
                <TImage
                  src={"/images/homepage/people-preview.svg"}
                  alt="Service Sold Stats"
                  width={90}
                />
              </Flex>
            </Paragraph>
          </Col>
          <Col
            xs={{ span: 22, offset: 0, order: 2 }}
            md={{ span: 0, offset: 1, order: 2 }}
          >
            <Paragraph
              className="stats2-conatiner"
              style={{ marginTop: "55px" }}
            >
              <Flex justify="space-between">
                <Col>
                  <Paragraph className="stats-text">
                    Creator earnings on topmate (2024)
                  </Paragraph>
                </Col>
                <TImage
                  src={"/images/homepage/earning-preview.svg"}
                  alt="Service Sold Stats"
                  width={100}
                />
              </Flex>
              <CountUp
                start={1003040}
                end={1003059}
                duration={10}
                enableScrollSpy
                prefix="$ "
                easingFn={easingFn}
                useEasing
                delay={0}
              >
                {({ countUpRef }) => (
                  <Paragraph className="stats-number">
                    {" "}
                    <span ref={countUpRef} />
                  </Paragraph>
                )}
              </CountUp>
            </Paragraph>

            <Paragraph
              className="stats2-conatiner"
              style={{ marginTop: "32px" }}
            >
              <Flex justify="space-between">
                <Col>
                  <Paragraph className="stats-text">A community of</Paragraph>
                  <Paragraph className="stats-number"> 10k+</Paragraph>
                </Col>
                <TImage
                  src={"/images/homepage/people-preview.svg"}
                  alt="Service Sold Stats"
                  width={90}
                />
              </Flex>
            </Paragraph>
          </Col>

          {/* Drawer */}
          {linkedinDrawerOpen && profileData?.data?.id && (
            <ProfilePreviewDrawer
              open={linkedinDrawerOpen}
              setOpen={setLinkedinDrawerOpen}
              data={profileData}
            />
          )}
        </Row>

        {/* Stats */}
        <Row justify="center">
          {/* Mobile Images */}
          <Col xs={0} md={0}>
            <Paragraph className="home-stats-container">
              <TImage
                src={ImgStatsMobile1}
                alt="Creator Stats"
                width={327}
                height={264}
                className="home-stats-img"
              />
              <Paragraph className="stats2-conatiner">
                <TImage
                  src={ImgStats2}
                  alt="Service Sold Stats"
                  width={72}
                  height={72}
                  styles={{ width: "72px" }}
                />
                <Paragraph className="stats-text">
                  Creator earnings on topmate (2023)
                </Paragraph>
                <CountUp
                  start={2104210}
                  end={2104239}
                  duration={10}
                  enableScrollSpy
                  prefix="$ "
                  easingFn={easingFn}
                  useEasing
                  delay={0}
                >
                  {({ countUpRef }) => (
                    <Paragraph className="stats-number">
                      {" "}
                      <span ref={countUpRef} />
                    </Paragraph>
                  )}
                </CountUp>
              </Paragraph>
            </Paragraph>
          </Col>
        </Row>
        <TImage
          src={"/images/homepage/earning-women.svg"}
          alt="Creator Stats"
          width={356}
          height={414}
          className="women-img-earning"
        />
      </Container>
    </Container>
  );
};

export default ProfilePreview;
