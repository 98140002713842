import { Col, Row, Spin } from "antd";
import Textra from "react-textra";

interface IFPageLoader {
  lottie?: any;
  text?: any;
}

const FullScreenLoader: React.FC<IFPageLoader> = ({ lottie, text }) => {
  return (
    <Row align="middle" justify="center" className="fs-loader">
      <Col span={24} className="text-center">
        {lottie ? lottie : <Spin />}
        {text?.length > 0 ? (
          <Textra
            data={text}
            effect="topDown"
            duration={1200}
            stopDuration={1200}
          />
        ) : (
          <Textra
            data={["Loading, Will be done in few secs"]}
            effect="topDown"
            duration={1000}
            stopDuration={2000}
          />
        )}
      </Col>
    </Row>
  );
};

export default FullScreenLoader;
